@import "../../common/css/common.css";

.header-section {
  background-color: var(--primary-bg-light);
  color: var(--primary-bg-text);
}

.title-panel {
  min-width: 350px;
}

#main-title {
  height: 35px;
  font-size: 35px;
  font-family: "Comfortaa";
  color: var(--secondary-cool-3);
}

#sub-title,
#copyright {
  height: 15px;
  font-size: 15px;
  font-family: "Source Code Pro", source-code-pro, "Courier New", monospace;
  color: var(--primary-main-3);
  padding: 15px 0 15px 0;
}

.dropdown-menu {
  background-color: var(--primary-bg-light);
  color: var(--primary-bg-text);
}

.choose-network-dropdown {
  padding: 5px 15px 5px 0;
}

.loopchain-info,
.chainalytic-info,
.network-id-info,
.iconex-info {
  color: var(--primary-main-3);
  font-size: 15px;
  padding: 5px 15px 5px 0;
}

.endpoint-input {
  height: 30px;
  font-size: 15px;
}
