@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://fonts.googleapis.com/css?family=Source Code Pro);
:root {
  /* Primary */
  --primary-light: #61daf2;
  --primary-light-text: #1f454d;

  --primary-main-1: #57c3d9;
  --primary-main-2: #47a1b3;
  --primary-main-3: #337380;
  --primary-main-text: #ffffff;

  --primary-dark: #1f454d;
  --primary-dark-text: #e6f0f2;

  /* Primary background */
  --primary-bg-white: #fafafa;
  --primary-bg-light: #edf1f2;
  --primary-bg-1: #e1e5e6;
  --primary-bg-2: #c8cbcc;
  --primary-bg-text: #4b4c4d;

  --primary-bg-dark: #919899;
  --primary-bg-dark-text: #ffffff;

  /* Secondary hot */
  --secondary-hot-1: #e5c0b8;
  --secondary-hot-dark-text: #4d4949;
  --secondary-hot-2: #e68873;
  --secondary-hot-3: #e65939;
  --secondary-hot-text: #f2e8e6;

  /* Secondary warm */
  --secondary-warm-1: #f2e2c2;
  --secondary-warm-2: #f2ca79;
  --secondary-warm-3: #f2b230;
  --secondary-warm-text: #4d4b49;

  /* Secondary cool */
  --secondary-cool-1: #b8e6d2;
  --secondary-cool-dark-text: #494d4b;
  --secondary-cool-2: #66cca1;
  --secondary-cool-3: #36b37e;
  --secondary-cool-text: #f2fffa;
}

.section-block {
  padding-top: 15px;
  padding-bottom: 15px;
}

.text-area:focus {
  outline: none !important;
  border: 1px solid #47a1b3;
  border: 1px solid var(--primary-main-2);
  box-shadow: 0 0 5px #337380;
  box-shadow: 0 0 5px var(--primary-main-3);
}

/* Custom link */

.custom-link {
  color: #337380;
  color: var(--primary-main-3);
}

.custom-link:hover {
  color: #36b37e !important;
  color: var(--secondary-cool-3) !important;
}

/* Separator */

.horizonal-separator {
  height: 5px;
  background-color: #919899;
  background-color: var(--primary-bg-dark);
  margin: 15px 0 15px 0;
}

.vertical-separator {
  width: 3px;
  background-color: #919899;
  background-color: var(--primary-bg-dark);
  margin: 0 15px 0 15px;
}

/* Custom hub button */

.hub-btn-primary {
  color: #ffffff;
  color: var(--primary-main-text);
  background: #47a1b3;
  background: var(--primary-main-2);
  border: none;
}
.hub-btn-primary:active,
.hub-btn-primary:hover,
.hub-btn-primary:focus {
  color: #ffffff !important;
  color: var(--primary-main-text) !important;
  background: #337380 !important;
  background: var(--primary-main-3) !important;
  border: none !important;
  border-style: none !important;
  box-shadow: none !important;
}

.hub-btn-secondary {
  color: #ffffff;
  color: var(--primary-main-text);
  background: #57c3d9;
  background: var(--primary-main-1);
  border: none;
}
.hub-btn-secondary:active,
.hub-btn-secondary:hover,
.hub-btn-secondary:focus {
  color: #ffffff !important;
  color: var(--primary-main-text) !important;
  background: #47a1b3 !important;
  background: var(--primary-main-2) !important;
  border: none !important;
  border-style: none !important;
  box-shadow: none !important;
}

/* Status label */

.succeed-label {
  font-size: 15px;
  font-weight: bold;
  height: 25px;
  background-color: #36b37e;
  background-color: var(--secondary-cool-3);
  color: #f2fffa;
  color: var(--secondary-cool-text);
  padding: 0 5px 5px 5px;
  text-align: center;
  border-radius: 4px;
}

.warning-label {
  font-size: 15px;
  font-weight: bold;
  height: 25px;
  background-color: #f2b230;
  background-color: var(--secondary-warm-3);
  color: #4d4b49;
  color: var(--secondary-warm-text);
  padding: 0 5px 5px 5px;
  border-radius: 4px;
}

.failed-label {
  font-size: 15px;
  font-weight: bold;
  height: 25px;
  background-color: #e65939;
  background-color: var(--secondary-hot-3);
  color: #f2e8e6;
  color: var(--secondary-hot-text);
  padding: 0 5px 5px 5px;
  text-align: start;
  border-radius: 4px;
}

/* Alert */

.alert-info {
  background-color: #b8e6d2;
  background-color: var(--secondary-cool-1);
  color: #494d4b;
  color: var(--secondary-cool-dark-text);
  border-style: none;
}

.alert-error {
  background-color: #e65939;
  background-color: var(--secondary-hot-3);
  color: #f2e8e6;
  color: var(--secondary-hot-text);
  border-style: none;
}

.alert-success {
  background-color: #66cca1;
  background-color: var(--secondary-cool-2);
  color: #f2fffa;
  color: var(--secondary-cool-text);
  border-style: none;
}

/* Info text */

.fetching {
  margin-left: 10px;
  margin-top: 5px;
  font-style: italic;
  color: #1f454d;
  color: var(--primary-dark);
}

body {
  margin: 0;
  font-family: "Montserrat";
  font-size: 15px;
  background-color: var(--primary-bg-white) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Source Code Pro", source-code-pro, "Courier New", monospace;
}

.form-control:focus {
  border-color: var(--primary-main-2);
  box-shadow: 0 0 2px var(--primary-main-3) inset;
  outline: 0 none;
}

.form-control {
  height: 30px;
}

.btn-primary {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn-secondary {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.header-section {
  background-color: var(--primary-bg-light);
  color: var(--primary-bg-text);
}

.title-panel {
  min-width: 350px;
}

#main-title {
  height: 35px;
  font-size: 35px;
  font-family: "Comfortaa";
  color: var(--secondary-cool-3);
}

#sub-title,
#copyright {
  height: 15px;
  font-size: 15px;
  font-family: "Source Code Pro", source-code-pro, "Courier New", monospace;
  color: var(--primary-main-3);
  padding: 15px 0 15px 0;
}

.dropdown-menu {
  background-color: var(--primary-bg-light);
  color: var(--primary-bg-text);
}

.choose-network-dropdown {
  padding: 5px 15px 5px 0;
}

.loopchain-info,
.chainalytic-info,
.network-id-info,
.iconex-info {
  color: var(--primary-main-3);
  font-size: 15px;
  padding: 5px 15px 5px 0;
}

.endpoint-input {
  height: 30px;
  font-size: 15px;
}

.tab-view-header-title-active {
  height: 35px;
  font-size: 30px;
  font-weight: bold;
  color: var(--primary-bg-text);
  background: none;
  border: none;
  padding: 0px;
}

.tab-view-header-title-inactive {
  height: 35px;
  font-size: 30px;
  font-weight: normal;
  color: var(--primary-bg-dark);
  background: none;
  border: none;
  padding: 0px;
}

.tab-view-header-title-active:active,
.tab-view-header-title-active:hover,
.tab-view-header-title-active:focus {
  color: var(--primary-bg-text) !important;
  background: none !important;
  border: none !important;
  border-style: none !important;
  box-shadow: none !important;
}

.tab-view-header-title-inactive:active,
.tab-view-header-title-inactive:hover,
.tab-view-header-title-inactive:focus {
  color: var(--primary-bg-text) !important;
  background: none !important;
  border: none !important;
  border-style: none !important;
  box-shadow: none !important;
}

.iconex-wallet-address {
  background-color: var(--secondary-cool-2);
  color: var(--secondary-cool-text);
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}
.inline-span {
  white-space: nowrap;
}

.utility-label {
  background-color: var(--primary-bg-2);
  color: var(--primary-bg-text);
  padding: 5px;
}






.hash-cell,
.block-cell,
.address-cell {
  font-family: "Source Code Pro", source-code-pro, "Courier New", monospace;
  color: var(--primary-main-2);
  height: 25px;
}

.age-cell,
.amount-cell,
.fee-cell {
  color: var(--primary-bg-dark);
  height: 25px;
}
.check-tx-cell {
  height: 25px;
}

.check-tx-button {
  height: 20px;
  font-size: 10px;
  color: var(--primary-bg-white);
  background-color: var(--primary-main-2);
}





.api-item {
  background-color: var(--primary-bg-1);
  color: var(--primary-bg-text);
}

.param-input {
  height: 30px;
}

.param-name {
  color: var(--primary-dark);
}

.icx-amount-input {
  margin-top: 5px;
  margin-left: 10px;
  height: 30px;
  font-size: 14px;
}

#contract-address-input {
  height: 30px;
}

#step-limit-input {
  height: 30px;
}

.api-list-title {
  color: var(--primary-bg-text);
  background: none;
}

.contract-name {
  background-color: var(--secondary-cool-2);
  color: var(--secondary-cool-text);
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.no-contract-name {
  background-color: var(--secondary-hot-2);
  color: var(--secondary-hot-text);
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.succeed-result-title {
  background-color: var(--secondary-cool-2);
  color: var(--secondary-cool-text);
  height: 30px;
  font-size: smaller;
  padding: 5px 10px 2px 10px;
}

.error-result-title {
  background-color: var(--secondary-hot-3);
  color: var(--secondary-hot-text);
  height: 30px;
  font-size: smaller;
  padding: 5px 10px 2px 10px;
}


.dropdown-menu {
  background-color: var(--primary-bg-light);
  color: var(--primary-bg-text);
}

.contract-hub {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}

